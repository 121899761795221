import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "client", "wage", "allowance", "template", "addProjectItem"
  ]

  connect () {
    super.connect()
    this.getDefaultValues();
  }

  getDefaultValues () {
    let clientId = this.clientTarget.value
    fetch('/api/get-client-default-values', {
      method: 'post',
      body: JSON.stringify({
        client_id: clientId
      }),
      credentials: "same-origin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-Token": this.getMetaValue("csrf-token")
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setValue(this.wageTargets, data.default_wage)
      this.setValue(this.allowanceTargets, data.default_kilometer_allowance)
    }).catch((error) => {
      //console.log(error)
     })
  }

  addProjectItem(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addProjectItemTarget.insertAdjacentHTML('beforebegin', content)
  }

  setValue(target, value) {
    target.forEach((el, i) => {
      el.value = parseFloat(value).toFixed(2);
    });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
