import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["template", "projectItemType", "time", "fixed", "kilometers", "name"]
  static values = { projectItemType: String }

  connect() {
    super.connect()
    this.switchStylingClasses(this.projectItemTypeValue)
  }

  removeItem(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  changeProjectItemType() {
    let type = this.projectItemTypeTarget.value
    this.switchStylingClasses(type)

  }

  switchStylingClasses(type) {
    switch(type) {
      case 'time':
        this.timeTarget.classList.remove('d-none')
        this.timeTarget.classList.add('d-block')

        this.fixedTarget.classList.remove('d-block')
        this.fixedTarget.classList.add('d-none')

        this.kilometersTarget.classList.remove('d-block')
        this.kilometersTarget.classList.add('d-none')

        this.nameTarget.classList.remove('d-none')
        this.nameTarget.classList.add('d-block')
        break;
      case 'fixed':
        this.fixedTarget.classList.remove('d-none')
        this.fixedTarget.classList.add('d-block')

        this.timeTarget.classList.remove('d-block')
        this.timeTarget.classList.add('d-none')

        this.kilometersTarget.classList.remove('d-block')
        this.kilometersTarget.classList.add('d-none')

        this.nameTarget.classList.remove('d-none')
        this.nameTarget.classList.add('d-block')
        break;
      case 'kilometers':
        this.kilometersTarget.classList.remove('d-none')
        this.kilometersTarget.classList.add('d-block')

        this.fixedTarget.classList.remove('d-block')
        this.fixedTarget.classList.add('d-none')

        this.timeTarget.classList.remove('d-block')
        this.timeTarget.classList.add('d-none')

        this.nameTarget.classList.remove('d-block')
        this.nameTarget.classList.add('d-none')
    }
  }
}
