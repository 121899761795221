import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "dateRange", "vehicleId"
  ]

  connect () {
    super.connect()

    // Initialize flatpickr range input
    flatpickr(".rangeInput", {
      mode: "range",
      defaultHour: 0,
      dateFormat: "Y-m-d",
      weekNumbers: true,
      onClose: (selectedDates, dateStr, instance) => {
        //this.stimulate('OverviewReflex#kilometers', dateStr)
      }
    })
  }

  filterKilometers () {
    if (this.dateRangeTarget.value.includes("to")) {
      this.stimulate('OverviewReflex#kilometers', this.dateRangeTarget.value, this.vehicleIdTarget.value)
    }
  }

  filterVehicle () {
    this.stimulate('OverviewReflex#kilometers', this.dateRangeTarget.value, this.vehicleIdTarget.value)
  }
}
