import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "grossAmount", "vatAmount", "totalAmount"
  ]

  connect () {
    super.connect()
  }

  calculateFromGrossAmount () {
    this.vatAmountTarget.value = (parseFloat(this.grossAmountTarget.value) * 0.21).toFixed(2)
    this.totalAmountTarget.value = (parseFloat(this.grossAmountTarget.value) * 1.21).toFixed(2)
  }

  calculateFromVatAmount () {
    this.grossAmountTarget.value = (parseFloat(this.vatAmountTarget.value) / 21 * 100).toFixed(2)
    this.totalAmountTarget.value = (parseFloat(this.vatAmountTarget.value) / 21 * 121).toFixed(2)
  }

  calculateFromTotalAmount () {
    this.grossAmountTarget.value = (parseFloat(this.totalAmountTarget.value) / 1.21).toFixed(2)
    this.vatAmountTarget.value = (parseFloat(this.totalAmountTarget.value) / 121 * 21).toFixed(2)
  }

}
