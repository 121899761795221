// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

require("@hotwired/turbo-rails")

require('@fortawesome/fontawesome-free/js/all')
require('@fortawesome/fontawesome-free/scss/fontawesome')
require('@fortawesome/fontawesome-free/scss/brands')
require('@fortawesome/fontawesome-free/scss/solid')

require('jquery.easing/jquery.easing')

require('bootstrap-colorpicker')
require("bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css")

window.Rails = Rails

import "controllers"

import 'bootstrap'
import 'data-confirm-modal'

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.min.css")

$(document).on("turbo:load", () => {
  console.log("turbo!")

  // Initialize bootstrap tooltip and popover
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()


  // Initialize flatpickr date input
  flatpickr(".dateInput", {
    weekNumbers: true
  })

  // Initialize color picker
  $('.color-picker').colorpicker({
    format: 'hex'
  })

  // Toggle the side navigation
  $("#sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("toggled");
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll', function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function(e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    e.preventDefault();
  });



  // document.querySelectorAll('[data-method="delete"]');
  dataConfirmModal.setDefaults({
    title: 'Weet je het zeker?',
    commit: 'Bevestigen',
    cancel: 'Annuleren'
  });

})


