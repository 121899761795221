import ApplicationController from './application_controller'

var myState = {
      pdf: null,
      currentPage: null,
      zoom: null,
      zoomRange: 0.25
    }

export default class extends ApplicationController {
  static targets = [
    "canvas", "currentPage", "totalPages"
  ]
  static values = {
    pdfFileUrl: String
  }

  connect () {
    super.connect()

    pdfjsLib.getDocument(this.pdfFileUrlValue).then((pdf) => {
      myState.pdf = pdf
      myState.currentPage = 1
      myState.zoom = 1.5
      this.totalPagesTarget.innerHTML = `/ ${pdf.numPages}`
      this.render();
   })
  }

  render () {
    myState.pdf.getPage(myState.currentPage).then((page) => {

      var canvas = this.canvasTarget;
      var ctx = canvas.getContext('2d');

      var viewport = page.getViewport(myState.zoom);

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      this.currentPageTarget.value = myState.currentPage;

      page.render({
          canvasContext: ctx,
          viewport: viewport
      });
    });
  }

  nextPage () {
    if(myState.pdf == null || myState.currentPage == myState.pdf.numPages)
    return;

    myState.currentPage += 1;
    this.render();
  }

  previousPage () {
    if(myState.pdf == null|| myState.currentPage == 1)
    return;

    myState.currentPage -= 1;
    this.render();
  }

  zoomIn () {
    if (myState.zoom >= myState.pdf.scale) {
      return;
    }
    myState.zoom += myState.zoomRange;
    this.render();
  }

  zoomOut () {
    if (myState.zoom >= myState.pdf.scale) {
      return;
    }
    myState.zoom -= myState.zoomRange;
    this.render();
  }

  zoomFit () {
    if (myState.zoom >= myState.pdf.scale) {
      return;
    }
    myState.zoom = 1;
    this.render();
  }

  pageNumber (event) {
    var pageNumber = Number(event.data);
    if(pageNumber > 0 && pageNumber <= myState.pdf.numPages) {
      myState.currentPage = pageNumber;
      this.render();
    }
  }
}
