import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "template", "addVehicle"
  ]

  connect () {
    super.connect()
  }

  addVehicle(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addVehicleTarget.insertAdjacentHTML('beforebegin', content)
  }
}
